
import { Component, Mixins } from 'vue-property-decorator';
import BoxListItem from './';
const Words: Dictionary = {
  btReserve: '予約する（法人優待）',
  btReserveResort: 'プランを確認する',
  btOfficialSite: '公式ホームページ',
};
@Component
export default class BoxListItemJa extends Mixins(BoxListItem) {
  private words = Words;
}
